<template>
  <div>
    <v-row>
      <v-col lg="6">
        
        <maintainer-crop :isAdmin="true" @rowSelected="rowSelected($event)"  :height="100"></maintainer-crop>
      </v-col>
      <v-col lg="6"> 
        <maintainer-article :isAdmin="true" :DedID="dedID" :height="200"></maintainer-article> 
        
      </v-col>
    </v-row>
  </div>
</template>

<script>
import MaintainerCrop from './MaintainerCrop.vue';
import MaintainerArticle from './MaintainerArticle.vue';
export default {
  components: {
    MaintainerCrop,    
    MaintainerArticle
  },

   data() {
    return {
      parentID: 0,
      dedID:0,
     
    };
  },

  methods: {
    rowSelected(rows) {
     this.dedID=rows[0].DedValue;
      
    },
   
  },
};
</script>
