import Service from "../Service";
const resource = "articles/";

export default {
    save(art, requestID) {
        return Service.post(resource + "save", art, { params: { requestID: requestID }, });
    },  
    pagination(parameters, requestID) {
        console.log('parametro',parameters)
        return Service.post(resource + "pagination", parameters, {
          params: { requestID: requestID },
        });
      }, 
};
