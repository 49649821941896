<template>
    <s-crud 
    title="Artículos existentes en SAP"
    :config="configArticles"
    @save="save($event)"
    add edit
    remove   
    :filter="filter"    
    ref="crud"
    >

      <template slot-scope="props">  
        <v-container>      
              <v-row justify="center">
                <v-col cols="10" class="s-col-form" sm="8" md="8" lg="8">
                    
                    <s-article-search v-model="objArticle" :placeholder="props.item.ArticleName"></s-article-search>
                  </v-col> 
                    <v-col cols="12" class="s-col-form" sm="4" md="4" lg="4">
                  <s-select-definition v-model="props.item.HmaStatus" :def="1246" label="Estado"></s-select-definition>
                </v-col>                            
              </v-row>
        </v-container>      
      </template>

           <template v-slot:HmaStatus="{ row }">
        <v-chip style="margin:0px" x-small :color="row.HmaStatus == 1 ? 'success' : 'error'">
            {{ row.HmaStatus == 1 ? "Activo" : "Inactivo" }}
        </v-chip>
      </template>
      <template v-slot:SecStatus="{ row }">
        <v-chip style="margin:0px" x-small :color="row.SecStatus == 1 ? 'success' : 'error'">
            {{ row.SecStatus == 1 ? "Activo" : "Inactivo" }}
        </v-chip>
      </template>  
     </s-crud>
</template>

<script>
import SSelectArticle from '@/components/TecnicalAssistence/CommitmentCarrierMaterial/sSelectArticle.vue';
import sArticleSearch from "@/components/Utils/Logistics/SArticleSearch";
import _sLocations from '../../../services/Logistics/ConsultingSap/LocationsService';
import _MaintainerCropArticleSapService from "@/services/Technicalassistance/MaintainerCropArticleSap/MaintainerCropArticleSapService";

export default {
  name: "MaintainerArticle",
  components: { SSelectArticle, sArticleSearch },

  props: {
    DedID:{ type: Number, default: 0 }, 
    DedValue:{ type: Number, default: 0 },    
  },

  data() {
    return {
      dialogEdit: false,  
      objArticle:{ },
      filter: {DedID: 0},
     };     
  },

  computed: {   
    configArticles() {
      return {
        model: {

          ArticleName: "string",
          HmaID:"ID",
          DedID:"int",            
          DedDescription: "string", 
          HmaStatus: "status",
          
          }, 
        service: _MaintainerCropArticleSapService,
        headers:  this.$fun.getSecurity().IsLevelAdmin  
          ? [
              
            { text: "ID", value: "HmaID", sortable: false },
            { text: "Artículo", value: "ArticleName", sortable: false},           
            { text: "Estado", value: "HmaStatus", sortable: false },      
            ]
          : [                       
            { text: "ID", value: "HmaID", sortable: false },
            {text: "Artículo", value: "ArticleName"},               
            { text: "Estado", value: "HmaStatus", sortable: false },            
            ],
      };
    }, 
    
    isAdmin() {
      this.$fun.getSecurity().IsLevelAdmin;
         },  
  },
  
  methods: {
 Initialize() {
   this.filter = {DedID: this.DedID}; 
 },

    edit(item) {
      this.dialogEdit = true;
          
    },

    rowSelected(item) {
      if (item.length > 0) {
        this.$emit("rowSelected", item);       
      }
    },

    save(item) {
      item.ArtID = this.objArticle.ArtCode;
      item.SecStatus = 1;
      console.log('guarda', item);
      console.log('Art', this.objArticle);
      item.DedID=this.DedID;
      
      if (this.objArticle.ArtCode != null || this.objArticle.ArtCode == null){
        item.ArtID = this.objArticle.ArtCode;
      }

      if(!item.ArtID){
            this.$fun.alert("Ingrese Artículo", "warning")
            return;
        }
        
        item.SecStatus=1;
        item.save();
       //limpia campo de artícle
       this.objArticle = {}
    },    
  },
  watch: {
    
    DedID(){  
      this.Initialize();    
      },
  },
   created() {
    this.filter = { DedID: 0 };
  },
};
</script>
