import Service from "../Service";
import Vue from "vue";
const resource = "listsupplies/";

export default {
  save(prd, requestID) {
    return Service.post(resource + "save", prd, { params: { requestID: requestID }, });
  },

  pagination(parameters, requestID) {
    return Service.post(resource + "pagination", parameters, {
      params: { requestID: requestID },
    });
  },
  listOriginSupplies(dedId, search, requestid){
   
   return Service.post(resource + "listOriginSupplies",{},{
      params:{DedId:dedId, Search:search, RequestID:requestid}
    });
  },
  GetUrllistOriginSupplies(){
       return Vue.prototype.$http.defaults.baseURL+"/listsupplies/listOriginSupplies";
  },
  GetUrlListByPlague(){
    return Vue.prototype.$http.defaults.baseURL+"/listsupplies/listByPlague";
},

  listplaguecultive(plId, search, requestid){   
    return Service.post(resource + "listplaguecultive",{},{
       params:{PlId:plId, Search:search, RequestID:requestid}
     });
   },
   
   GetUrllistplaguecultive(){
        return Vue.prototype.$http.defaults.baseURL+"/listsupplies/listplaguecultive";
   },

  //pdf
};
