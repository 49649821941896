import Service from "../../Service";
import Vue from "vue";
const resource = "HarvestMaterial/";

export default {
    save(prd, requestID) {
      return Service.post(resource + "save", prd, {
        params: { requestID: requestID },
      });
    },
  
    pagination(parameters, requestID) {
      return Service.post(resource + "pagination", parameters, {
        params: { requestID: requestID },
      });
    },
    listMaterial(DedValue, requestID){
      return Service.post(resource + "listMaterial", DedValue,{
        params: {requestID: requestID, DedValue: DedValue},
      });
    }
  };